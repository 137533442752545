/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './App.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './i18n/en';
import Back from './assets/assets/img/window.jpg';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation:
          english,
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <div className="title">
      Maintenance Window.
      <div className="text">
        We are currently updating th services for you. Will be back up soon.
      </div>
    </div>
  );
}

export default App;
