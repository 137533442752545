const english = {
  // login page
  login_welcome_back: 'Welcome back! Please signin to continue.',
  email: 'Email',
  email_address: 'Email address',
  password: 'Password',
  foregot_pass: 'Forgot password?',
  enter_email: 'Enter your email address',
  enter_pass: 'Enter your password',
  sign_in: 'Sign In',
  login_contact_message: 'For any login issues please',
  login_contact_link_text: ' contact us',
  interpolateSample: 'you <strong>can</strong> interpolate {{value}} or {{component}} via interpolate component!',

  // menu
  m_dashboard: 'Dashboard',
  m_general: 'General',
  m_user_management: 'User Management',
  m_users: 'Users',
  m_groups: 'Groups',
  m_company: 'Company',
  m_companies: 'Companies',
  m_settings: 'Settins',
  m_cases: 'Cases',
  m_imports: 'Imports',
  m_billing: 'Billing',
  m_invoices: 'Invoices',
  m_links: 'Llinks',

  // users page
  u_id: 'Id',
  u_user_management: 'User Management',
  u_users: 'Users',
  u_first_name: 'First Name',
  u_last_name: 'Last Name',
  u_email: 'Email',
  u_mobile: 'Mobile',
  u_group: 'Group',
  u_company: 'Company',
  u_active: 'Is Enabled',
  u_create_user: 'Create User',
  u_update_user: 'Update User',
  delete_users: 'Delete user(s)',
  delete_users_message: 'The deletion is not reversible. Confirmig will permanently delete the data.',

  // generic
  created_at: 'Created At',
  updated_at: 'Updated At',
  change_password: 'Change Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  first_name: 'First Name',
  last_name: 'Last Name',
  repassword: 'Confirm password',
  mobile: 'Mobile',
  group: 'Group',
  company: 'Company',
  is_active: 'Is Active',

};

export default english;
